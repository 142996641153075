<template>
  <div class="v_register">
    <div class="v-register-title">
      <label class="iconfont icon-fanhui" @click="$router.replace({ name: 'login' })"></label>
      <span>注册</span>
      <!-- <div class="v-register-line"></div> -->
    </div>
    <div class="v-register-form g-flex-column g-flex-align-center">
      <!-- 邀请码 -->
      <div class="v-register-form-item g-flex-column">
        <div class="v-register-form-item-title">
           <span>邀请码</span>
           <!-- <div class="v-register-line"></div> -->
        </div>
        <van-field v-model="form.tcode"  placeholder="请输入从上级获取的邀请码"/>
      </div>
      <!-- 手机号 -->
      <div class="v-register-form-item g-flex-column">
       <div class="v-register-form-item-title">
           <span>手机号</span>
           <!-- <div class="v-register-line"></div> -->
        </div>
        <van-field v-model="form.mobile"  placeholder="请输入手机号"/>
      </div>
      <!-- 登录密码 -->
      <div class="v-register-form-item g-flex-column">
       <div class="v-register-form-item-title">
           <span>登录密码</span>
           <!-- <div class="v-register-line"></div> -->
        </div>
        <div class="v-register-form-item-pwd g-flex-align-center">
          <van-field v-model="form.password" :type="pwdType"  placeholder="请输入登录密码"/>
          <i class="iconfont" :class="pwdType ==='password' ? 'icon-bukejian': 'icon-mimakejianguanbi'" @click="pwdTypeClick"></i>
        </div>
      </div>
      <!-- 确认登录密码 -->
      <div class="v-register-form-item g-flex-column">
       <div class="v-register-form-item-title">
           <span>确认密码</span>
           <!-- <div class="v-register-line"></div> -->
        </div>
        <div class="v-register-form-item-pwd g-flex-align-center">
          <van-field v-model="form.password_two" :type="pwdTypeSecond"  placeholder="请再次输入密码"/>
          <i class="iconfont" :class="pwdTypeSecond ==='password' ? 'icon-bukejian': 'icon-mimakejianguanbi'" @click="pwdTypeSecondClick"></i>
        </div>
      </div>
      <!-- 验证码 -->
      <div class="v-register-form-item g-flex-column">
       <div class="v-register-form-item-title">
           <span>验证码</span>
           <!-- <div class="v-register-line"></div> -->
        </div>
        <div class="v-register-item-code g-flex-align-center">
          <van-field v-model="form.mobile_code"  placeholder="请输入获取的验证码"/>
          <div class="v-register-code-btn g-flex-align-center g-flex-justify-center" @click="getCode">{{sendCodeText}}</div>
        </div>
      </div>

    </div>

    <div class="g-flex-justify-between">
      <operation-button :buttonObj="buttonObj" @clickItem="emitLoginClick"/>
    </div>

    <div class="v-register-bottom g-flex-justify-end">
      <span class="v-register-login" @click="$router.replace({ name: 'login' })">已有账号，去登录</span>
    </div>

  </div>
</template>

<script>
import OperationButton from '../../components/OperationButton.vue'
import { apiSendCode, apiRegister } from '../../utils/api.js'
  export default {
    components: { OperationButton },
    data() {
      return {
        pwdTypeSecond: 'password',
        pwdType: 'password',
        timer: 0,
        sendCodeText: '获取',
        defaultCodeText:'重新获取',
        buttonObj: {
          title: '注册',
          claSS: 'c-btn-default',
          event: 'apiRegisterHandel',
        },
        form: {
          mobile: '',
          mobile_code: '',
          password: '',
          password_two: '',
          tcode: ''
        }
      }
    },
    methods: {
      pwdTypeSecondClick() {
        if(this.pwdTypeSecond === 'password') return this.pwdTypeSecond = 'text'
        return this.pwdTypeSecond = 'password'
      },
      
      pwdTypeClick() {
        if(this.pwdType === 'password') return this.pwdType = 'text'
        return this.pwdType = 'password'
      },

      emitLoginClick(event) {
        if(!event) return
        this[event]()
      },
      // 注册
      async apiRegisterHandel() {
        if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
        // if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试');
        if(!this.form.password) return this.$toast('密码不能为空')
        if(this.form.password !== this.form.password_two) return this.$toast('请确认两次密码输入是否一致')
        if(!this.form.mobile_code) return this.$toast('请输入验证码')
        this.$global.loadingShow = true
        const { success, data } = await apiRegister(this.form)
        if(!success) return
        console.log(data)
        this.$toast(data.msg)
        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 1000);
      },

      // 获取验证码
      getCode() {
        if(!(/^1\d{10}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
        // if(!(/^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/.test(this.form.mobile))) return this.$toast('手机号码有误，请重试'); 
        if(this.timer) return
        this.apiSendCodeHandel()
      },

      // 发送验证码
      async apiSendCodeHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiSendCode({ mobile: this.form.mobile })
        if(!success) return
        let timeNum = 60
        this.$toast(data.msg)
        this.timer = setInterval(() => {
          timeNum --
          this.showNum = timeNum === 0 ? false : true
          this.sendCodeText = timeNum === 0 ? this.defaultCodeText : timeNum + 's'
          if(timeNum === 0) {
            clearInterval(this.timer)
            this.timer = 0
          }
        }, 1000)
      }
    }
  }
</script>

<style lang="scss">
.v_register {
  width: 100%;
  // height: 100%;
  // overflow: auto;
  // 登录标题
  .v-register-title {
    width: 130px;
    height: 100px;
    font-weight: bold;
    color: $black;
    position: relative;
    // 箭头
    .icon-fanhui {
      position: absolute;
      bottom: 0;
      font-size: 40px;
      font-weight: normal;
      z-index: 99;
      left: 10px;
      color: $main_color;
    }
    span {
      font-size: 32px;
      position: absolute;
      bottom: 0px;
      z-index: 99;
      right: 0;
    }
    .v-register-line {
      position: absolute;
      height: 10px;
      width: 100%;
      background: $main_color;
      bottom: 0;
      z-index: 88;
    }
  }
  // 表单
  .v-register-form {
    padding: 50px 40px 30px 50px;
    .v-register-form-item {
      align-self: stretch;
      .v-register-form-item-title {
        height: 30px;
        position: relative;
        span {
          padding-left: 10px;
          position: absolute;
          font-size: 18px;
          bottom: 0;
          z-index: 99;
          color: $black;
          font-weight: 600;
        }
        .v-register-line {
          position: absolute;
          height: 3px;
          width: 40px;
          background: $main_color;
          bottom: 4px;
          left: 34px;
          z-index: 88;
        }
      }

      // 密码
      .v-register-form-item-pwd {
        position: relative;
        .iconfont {
          position: absolute;
          right: 0;
          font-size: 24px;
          padding: 5px;
        }
      }
      // 设置输入框
      .van-cell {
        padding-left: 10px;
        border-bottom: 1px solid #B5B5B5;
        input {
          &::-ms-input-placeholder {
            color: #B2B2B2;
          }
          &::-webkit-input-placeholder {
            color: #B2B2B2;
          }
        }
      }
      // 验证码
      .v-register-item-code {
        border-bottom: 1px solid #B5B5B5;
        .van-cell {
          border-bottom: 0;
          flex: 1;
        }
        .v-register-code-btn {
          width: 70px;
          height: 30px;
          background: #FED9E1;
          color: $main_color;
          border-radius: 20px;
          font-size: 14px;
        }
      }
      // 登陆密码
      &:nth-of-type(3) {
        // margin-top: 24px;
        .v-register-form-item-title {
          .v-register-line {
            width: 60px;
          }
        }
      }
    }
  }

  .v-register-bottom {
    padding: 10px 30px;
    .v-register-login {
      color: $main_color;
      font-size: 12px;
      padding: 6px;
    }
  }
}
</style>